.notification-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 93vh;
}

.notification-section {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 2vw;
  padding: 2vw;
  font-size: 1.7vw;
  grid-template-columns: 1fr;
  box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.25), 0 1vw 1vw rgba(0, 0, 0, 0.22);
  border-radius: 0.5vw;
}

.notification-tooltip {
  font-size: 1.7vw !important;
  max-width: 100vw !important;
}

.notification-title-label {
  font-size: 4vw;
}

.notification-overlay-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.notification-link {
  cursor: pointer;
  text-decoration: none;
}

.notification-link-icon {
  cursor: pointer;
  text-decoration: none;
}

.notification-date-column {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 40vw;
}

.notification-message-column {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 40vw;
}

@media (hover: hover) {
  .notification-link-icon:hover {
    cursor: pointer;
    color: cornflowerblue;
  }
}

.notification-empty-cart {
  padding: 3vw;
  font-size: 2.4vw;
}

@media screen and (max-width: 576px) {
  .notification-title-label {
    font-size: 7vw;
  }

  .notification-section {
    font-size: 3.5vw;
  }

  .notification-date-column {
    max-width: 30vw;
  }

  .notification-message-column {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 15vw;
  }

  .notification-empty-cart {
    padding: 3vw;
    font-size: 5vw;
  }
}

/* 
@media screen and (max-width: 992px) {
  .notification-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 93vh;
  }
} */

.notification-table-column-center {
  text-align: center;
}
