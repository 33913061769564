.about-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 80vh;
}

.about-section {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 2vw;
  padding: 5vw;
  font-size: 2.5vw;
  grid-template-columns: 1fr;
  box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.25), 0 1vw 1vw rgba(0, 0, 0, 0.22);
  border-radius: 0.5vw;
}

.about-copyright {
  margin-bottom: 1vw;
  font-size: 1.7vw;
}

.about-message-container {
  display: flex;
  flex-direction: row;
}

.about-label {
  font-weight: 400;
}

.about-label-email {
  font-weight: 500;
  color: cornflowerblue;
  margin-left: 0.5vw;
}

@media screen and (max-width: 576px) {
  .about-section {
    font-size: 3.5vw;
  }

  .about-copyright {
    font-size: 2.8vw;
  }
}
