.checkout-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 93vh;
}

.checkout-section {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 2vw;
  padding: 2vw;
  font-size: 2.5vw;
  grid-template-columns: 1fr;
  box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.25), 0 1vw 1vw rgba(0, 0, 0, 0.22);
  border-radius: 0.5vw;
}

.checkout-cart-label {
  font-size: 4vw;
}

.checkout-table {
  font-size: 2.5vw;
}

.checkout-table-product-column {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 40vw;
}

.checkout-table-column-right {
  text-align: right;
  margin: 3vw;
}

.checkout-delete-item-button {
  font-size: 1.5vw;
}

.checkout-total-label {
  align-self: flex-end;
  font-weight: bold;
}

.checkout-buy-link {
  cursor: pointer;
  font-weight: 700;
  text-decoration: none;
}

.checkout-empty-cart {
  padding: 3vw;
  font-size: 2.4vw;
}

.checkout-component {
  margin-top: 1vw;
  align-self: flex-end;
}

.checkout-button {
  appearance: none;
  outline: none;
  border: none;
  background-color: orange;
  box-shadow: 0.1vw 0.1vw 0.5vw dimgray;
  border-radius: 0.5vw;
  padding: 0.5vw 2vw 0.5vw 2vw;
  color: black;
  font-weight: 700;
  font-size: 1.5vw;
  transition: all 0.3s;
}

@media (hover: hover) {
  .checkout-button:hover,
  .checkout-button:focus {
    box-shadow: 0.1vw 0.1vw 0.8vw orange;
    cursor: pointer;
  }
}

.checkout-button:active {
  box-shadow: 0.1vw 0.1vw 1vw orange;
  background-color: orange;
}

.checkout-button:disabled {
  box-shadow: 0.1vw 0.1vw 1vw darkorange;
  background-color: darkorange;
  cursor: auto;
}

.checkout-spinner {
  margin-left: 0.5vw;
  width: 1.5vw !important;
  height: 1.5vw !important;
  border: 0.2vw solid currentColor !important;
  border-right-color: transparent !important;
}

@media screen and (max-width: 576px) {
  .checkout-table-product-column {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 50vw;
  }

  .checkout-button {
    font-size: 5vw;
  }

  .checkout-section {
    font-size: 4vw;
  }

  .checkout-table {
    font-size: 4vw;
  }

  .checkout-cart-label {
    font-size: 7vw;
  }

  .checkout-empty-cart {
    padding: 3vw;
    font-size: 3.8vw;
  }

  .checkout-spinner {
    width: 5vw !important;
    height: 5vw !important;
  }
}

/* 
@media screen and (max-width: 992px) {
  .checkout-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 93vh;
  }
} */
