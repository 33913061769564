.u-centered-content {
  display: flex;
}

.u-full-vw {
  /* width: 97vw; */
}

.u-full-vh {
  height: 90vh;
}

.popup-custom-margin {
  margin-top: 5vw;
}

.tooltip-inner {
  max-width: 30vw !important;
}

.data-amplify-authenticator {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin: auto;
  height: 100%;
}

.data-amplify-authenticator div[data-amplify-container] {
  box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.25), 0 1vw 1vw rgba(0, 0, 0, 0.22);
  /* width: 60vw; */
}

.data-amplify-authenticator div {
  border-radius: 0.5vw;
}

.amplify-tabs button {
  border-radius: 0.5vw;
}

.amplify-tabs-item[data-state="active"] {
  color: orange;
  border-color: orange;
  /* font-size: 1.5vw; */
}

.amplify-tabs-item[data-state]:not([data-state="active"]) {
  color: darkgray;
  font-weight: 400;
  /* font-size: 1.5vw; */
}

.portal-container-empty {
  display: flex;
  flex-direction: column;
  /* margin: 0.5vw; */
  align-items: center;
  justify-content: center;
  /* width: 97vw; */
  min-height: 90vh;
}

.portal-spinner {
  width: 20vw !important;
  height: 20vw !important;
  border: 0.2vw solid currentColor !important;
  border-right-color: transparent !important;
}

.portal-text-label {
  font-size: 2.5vw;
  white-space: nowrap;
  color: cornflowerblue;
  font-family: bd-geminis, sans-serif;
  font-weight: 400;
  font-style: normal;
  height: 100%;
}

.flip-animation {
  animation: flip 5s infinite;
  animation-delay: calc(0.2s * var(--animation-index));
}

@keyframes flip {
  10% {
    transform: rotateY(360deg);
  }
  90% {
    transform: rotateY(360deg);
  }
}

.portal-text-container {
  display: flex;
  flex-direction: row;
}

@media (hover: hover) {
  .amplify-button[data-variation="primary"]:hover {
    background-color: cornflowerblue;
  }
}

.amplify-button {
  --amplify-components-button-primary-background-color: orange;
  --amplify-components-button-link-color: orange;
}

.table {
  margin-bottom: 0px !important;
  padding: 1vw !important;
}

.table > :not(:first-child) {
  border-top: 0.1vw solid currentColor !important;
}

.table-bordered > :not(caption) > * > * {
  padding: 0.5vw !important;
  border-width: 0 0.05vw !important;
}

.table-bordered > :not(caption) > * {
  border-width: 0.05vw 0 !important;
}

@media screen and (max-width: 992px) {
  .popup-custom-margin {
    margin-top: 4vw;
  }
}

@media screen and (max-width: 576px) {
  .table-bordered > :not(caption) > * > * {
    padding: 1vw !important;
    border-width: 0 0.05vw !important;
  }

  .table-bordered > :not(caption) > * {
    border-width: 0.05vw 0 !important;
  }

  .store-container-empty {
    display: flex;
    margin: 0.5vw;
    align-content: center;
    align-items: flex-start;
    justify-content: center;
    padding: 2vw;
  }

  .portal-container-empty {
    display: flex;
    flex-direction: column;
    /* margin: 0.5vw; */
    align-items: center;
    justify-content: center;
    /* width: 97vw; */
    min-height: 90vh;
  }

  .portal-spinner {
    width: 50vw !important;
    height: 50vw !important;
    border: 0.5vw solid currentColor !important;
    border-right-color: transparent !important;
  }

  .portal-text-label {
    font-size: 6vw;
  }
}
