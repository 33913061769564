.navigation-bar {
  height: 5vw;
}

.navigation-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.navigation-section {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  font-size: 2.5vw;
}

.navigation-right-section {
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  flex-direction: row;
  width: 100%;
}

.navbar-brand {
  white-space: nowrap;
  margin-left: 1vw;
  margin-right: 1vw !important;
  padding-top: 0vw !important;
  padding-bottom: 0vw !important;
}

.navbar-brand-container {
  display: flex;
  height: 100%;
}

.navbar-toggle {
  margin-right: 0.5vw;
}

.navbar-brand-text-label {
  font-size: 2.5vw;
  cursor: pointer;
  white-space: nowrap;
  color: orange;
  font-family: bd-geminis, sans-serif;
  font-weight: 400;
  font-style: normal;
  height: 100%;
}

.nav-normal-text-label {
  padding: 0px;
  cursor: pointer;
  font-weight: bold;
  white-space: nowrap;
  height: 100%;
}

.nav-vertical-delimiter {
  border-left: 0.1vw solid gray;
  margin-left: 0.5vw;
  margin-right: 0.5vw;
}

.nav-vertical-delimiter-hidden {
  display: none;
  border-left: 0.1vw solid gray;
  margin-left: 0.5vw;
  margin-right: 0.5vw;
}

.nav-item-generic {
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  max-height: 4vw;
}

.nav-item-login {
  color: lightgray;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-item-login-container {
  color: lightgray;
  display: flex;
  align-items: center;
}

.nav-register-label {
  font-size: 1.5vw;
  padding: 0px;
  color: lightgray;
  white-space: nowrap;
}

.nav-register-link {
  font-size: 1.5vw;
  padding-left: 0.3vw;
  cursor: pointer;
  font-weight: bold;
  color: gray;
  white-space: nowrap;
  text-decoration: none;
}

.nav-item-cart {
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-item-notifications {
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-link-item {
  display: flex;
  justify-content: center;
  padding: 0vw !important;
}

.nav-shopping-cart {
  font-size: 1.5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.nav-notifications {
  font-size: 1.5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-right: 0.5vw;
}

.nav-item-cart .fa-stack[data-count]:after {
  position: relative;
  bottom: 0.1vw;
  content: attr(data-count);
  font-size: 1.3vw;
  display: flex;
  padding: 0.4vw 0.2vw 0.4vw 0.2vw;
  border-radius: 0.4vw;
  line-height: 0.5vw;
  color: cornflowerblue;
  font-weight: 500;
  background: white;
  border-style: solid;
  border-width: 0.16vw;
}

.nav-item-notifications .fa-stack[data-count]:after {
  position: relative;
  bottom: 0.1vw;
  content: attr(data-count);
  font-size: 1.3vw;
  display: flex;
  padding: 0.4vw 0.2vw 0.4vw 0.2vw;
  border-radius: 0.4vw;
  line-height: 0.5vw;
  color: cornflowerblue;
  font-weight: 500;
  background: white;
  border-style: solid;
  border-width: 0.16vw;
}

@media (hover: hover) {
  .link-item-hover:hover {
    cursor: pointer;
    color: cornflowerblue !important;
  }

  .icon-item-hover:hover {
    cursor: pointer;
    color: cornflowerblue !important;
  }
}

@media screen and (max-width: 576px) {
  .navigation-bar {
    height: 100%;
  }

  .navigation-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .navigation-section {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
  }

  .navigation-right-section {
    display: flex;
    justify-content: stretch;
    flex-direction: column;
    width: 100%;
  }

  .navbar-brand {
    white-space: nowrap;
    padding-left: 2vw;
  }

  .navbar-brand-text-label {
    font-size: 5.6vw;
    cursor: pointer;
    white-space: nowrap;
    color: orange;
  }

  .nav-vertical-delimiter {
    border-top: 0.1vw solid gray;
    margin-top: 0.5vw;
    margin-bottom: 0.5vw;
  }

  .nav-vertical-delimiter-hidden {
    display: flex;
    border-top: 0.1vw solid gray;
    margin-top: 0.5vw;
    margin-bottom: 0.5vw;
  }

  .nav-item-generic {
    margin: 3.2vw;
  }

  /* .nav-item-generic {
    display: flex;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0.5vw;
    padding-right: 0.5vw;
    max-height: 4vw;
  } */

  .nav-item-cart {
    margin: 0.2vw;
  }

  .nav-item-notifications {
    margin: 0.2vw;
  }

  .nav-item-login {
    margin: 2vw;
  }

  .nav-normal-text-label {
    font-size: 6vw;
    padding: 0px;
    cursor: pointer;
    font-weight: bold;
  }

  .nav-shopping-cart {
    font-size: 5vw;
    min-height: 10vw;
  }

  .nav-notifications {
    font-size: 5vw;
    min-height: 10vw;
    margin-left: 3vw;
  }

  .nav-item-login-container {
    color: lightgray;
    display: flex;
  }

  .nav-register-label {
    font-size: 5vw;
    padding: 0vw;
    padding-right: 0.2vw;
    color: lightgray;
    white-space: nowrap;
    width: 50%;
  }

  .nav-register-link {
    font-size: 5vw;
    padding: 0vw;
    padding-left: 1vw;
    cursor: pointer;
    font-weight: bold;
    color: gray;
  }

  .nav-item-cart .fa-stack[data-count]:after {
    position: relative;
    bottom: 0%;
    display: flex;
    content: attr(data-count);
    font-size: 5vw;
    padding-top: 0.2vw;
    padding-left: 0.2vw;
    padding-right: 0.2vw;
    padding-bottom: 0.35vw;
    border-radius: 1vw;
    line-height: 4vw;
    color: cornflowerblue;
    font-weight: bold;
    background: white;
    border-style: solid;
    border-width: 0.15vw;
  }

  .nav-item-notifications .fa-stack[data-count]:after {
    position: relative;
    bottom: 0%;
    content: attr(data-count);
    font-size: 5vw;
    display: flex;
    padding-top: 0.2vw;
    padding-left: 0.2vw;
    padding-right: 0.2vw;
    padding-bottom: 0.35vw;
    border-radius: 1vw;
    line-height: 4vw;
    color: cornflowerblue;
    font-weight: bold;
    background: white;
    border-style: solid;
    border-width: 0.15vw;
  }
}
